import {
  readonly,
  ref,
  useRoute,
  useContext,
} from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { EntityUrl } from '~/modules/GraphQL/types';
import type { UseUrlResolverErrors, UseUrlResolverInterface } from './UseUrlResolver';
import { RoutableInterface } from '~/modules/GraphQL/types';

/**
 * The `useUrlResolver()` composable allows searching the resolver for current
 * route path (URL).
 *
 * See the {@link UseUrlResolverInterface} for a list of methods and values available in this composable.
 */
export function useUrlResolver(): UseUrlResolverInterface {
  const route = useRoute();
  const { error: nuxtError, app } = useContext();
  const context = app.$vsf;
  const { path } = route.value;
  const loading = ref(false);
  const error = ref<UseUrlResolverErrors>({
    search: null,
  });

  const search = async (): Promise<RoutableInterface | null> => {
    loading.value = true;
    let results: EntityUrl = {};
    const regSimpleUrl = /\/[cp|]\//gi;
    const regUrlWithPrefix = /[\w.-]+\/[cp|]\//gi;

    try {
      const clearUrl = !regUrlWithPrefix.test(path) ? path.replace(regSimpleUrl, '') : path.replace(regUrlWithPrefix, '');
      const { data } = await context.$magento.api.route(clearUrl);
      results = data?.route ?? null;
      if (!results) nuxtError({ statusCode: 404 });

    } catch (err) {
      error.value.search = err;

    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    path,
    search,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './UseUrlResolver';
export default useUrlResolver;
