


















import { SfButton, SfSearchBar } from '@storefront-ui/vue';
import { defineComponent, ref, watch, useRoute } from '@nuxtjs/composition-api';
import debounce from 'lodash.debounce';
import { clickOutside } from '~/utilities/directives/click-outside/click-outside-directive';
import SvgImage from '~/components/General/SvgImage.vue';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Products } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'set-is-open',
    'set-search-results',
    'set-search-term',
    'set-debounced-search-term',
    'set-loading-product',
  ],
  setup(props, { emit }) {
    const term = ref('');
    const route = useRoute();
    const { getProductList } = useProduct();
    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit('set-is-open', true);
        emit('SearchBar:toggle', true);
        if (document) {
          document.body.classList.add('no-scroll');
        }
      }
    };

    const hideSearch = () => {
      emit('set-is-open', false);
      emit('set-search-results', null);
      if (document) {
        document.body.classList.remove('no-scroll');
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
          term.value = '';
        }
      } else {
        hideSearch();
        term.value = '';
      }
    };
    const debouncedSearch = debounce(async (searchTerm) => {
      emit('set-loading-product', true);
      emit('set-debounced-search-term', searchTerm);
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      const productList: Products = (await getProductList({
        pageSize: props.itemsPerPage,
        search: term.value,
      })) as unknown as Products;

      emit('set-loading-product', false);
      emit('set-search-results', productList!.items);
    }, 1000);

    const handleSearch = (searchTerm) => {
      term.value = searchTerm;
      emit('set-search-term', searchTerm);
      debouncedSearch(searchTerm);
      showSearch();
      setTimeout(
        function () {
          term.value = searchTerm;
          emit('set-search-term', searchTerm);
          debouncedSearch(searchTerm);
          showSearch();
        }.bind(this),
        300
      );
    };

    watch(route, () => {
      hideSearch();
      term.value = '';
    });

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      handleSearch,
      term,
    };
  },
});
