























import { useContext, ref, computed } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import StoreSwitcher from '../StoreSwitcher/StoreSwitcher.vue';
import PriceHider from '@/components/Header/PriceHider/PriceHider.vue';
import { useUser } from '~/modules/customer/composables/useUser';
import { useIsMobile } from '@/helpers/mobileDevices';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const isMobile = useIsMobile();
  const {
    app
  } = useContext();
  const navigations = ref<Array<[]>>([{
    name: 'Search_my_order',
    link: 'retrouver-ma-commande'
  }, {
    name: 'Contact_us',
    link: 'contact'
  }, {
    name: 'Stores',
    link: 'magasins'
  }]);
  const {
    isAuthenticated
  } = useUser();
  const emit = __ctx.emit;
  return {
    app,
    navigations,
    isAuthenticated,
    emit
  };
};
__sfc_main.components = Object.assign({
  SfLink,
  PriceHider,
  StoreSwitcher
}, __sfc_main.components);
export default __sfc_main;
