



























































































































































import {
  SfMegaMenu,
  SfProductCard,
  SfScrollable,
  SfMenuItem,
  SfButton,
  SfLoader
} from '@storefront-ui/vue';
import { defineComponent, computed, ref, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useUiHelpers, useImage } from '~/composables';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import { handleDiscountPercentage } from '~/modules/catalog/product/helpers/handleDiscountPercentage';
import type { Product } from '~/modules/catalog/product/types';
import Vue from 'vue';

export default defineComponent({
  name: 'SearchResults',
  components: {
    SfMegaMenu,
    SfProductCard,
    SfScrollable,
    SfMenuItem,
    SfButton,
    SvgImage,
    SfLoader
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loadingProduct: {
      type: Boolean,
      default: false
    },
    searchTerm: {
      type: String,
      default: ''
    },
    debouncedSearchTerm: {
      type: String,
      default: ''
    },
    searchResults: {
      type: Array as PropType<Product[] | null>,
      default: () => []
    }
  },
  emits: ['set-is-close'],
  setup(props, { emit }) {
    const { isAuthenticated } = useUser();
    const { getMagentoImage, imageSizes } = useImage();
    const { isInWishlist, addItem, removeItem } = useWishlist();
    const th = useUiHelpers();
    const loading = ref(false);

    const searchResultsWithWishlistInfo = computed(() =>
      props.searchResults?.map((product) => ({
        ...product,
        isInWishlist: isInWishlist({ product })
      }))
    );

    const addItemToWishlist = async (product) => {
      await (isInWishlist({ product })
        ? removeItem({ product })
        : addItem({ product }));
    };

    function closeSearch() {
      emit('set-is-close');
    }

    const setLoading = () => {
      if (props.searchTerm !== props.debouncedSearchTerm) loading.value = true;
      else loading.value = false;
    };

    watch(props, () => {
      setLoading();
    });

    return {
      th,
      isInWishlist,
      isAuthenticated,
      productGetters,
      getMagentoImage,
      imageSizes,
      addItemToWishlist,
      searchResultsWithWishlistInfo,
      closeSearch,
      handleDiscountPercentage,
      loading
    };
  }
});
