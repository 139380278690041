



















































































import { SfBottomNavigation, SfCircleIcon, SfBadge } from '@storefront-ui/vue';
import {
  defineComponent,
  useRouter,
  useContext,
  computed,
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { useCart } from '~/modules/checkout/composables/useCart';

const MobileCategorySidebar = () =>
  import(
    '~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue'
  );

export default defineComponent({
  components: {
    SfBottomNavigation,
    SfCircleIcon,
    MobileCategorySidebar,
    SvgImage,
    SfBadge,
  },
  setup() {
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer' }));
      } else {
        toggleLoginModal();
      }
    };
    const { cart } = useCart();

    const redirectOnBasket = async () =>
      await router.push(`${app.localePath('/panier')}`);

    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
      toggleMobileMenu();
    };

    return {
      isAuthenticated,
      isMobileMenuOpen,
      redirectOnBasket,
      toggleWishlistSidebar,
      toggleCartSidebar,
      toggleMobileMenu,
      loadCategoryMenu,
      handleAccountClick,
      app,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
    };
  },
});
