




























import { useRoute, ref, computed } from '@nuxtjs/composition-api';
import { useLanguageStore } from '../StoreSwitcher/storeLanguage.ts';
const __sfc_main = {};
__sfc_main.props = {
  color: {
    type: String,
    required: false,
    default: 'black'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const route = useRoute();
  const languageStore = useLanguageStore();
  const storeLanguage = computed(() => {
    return languageStore.language ?? (route.value.fullPath.includes('b2bFR') ? 'b2bFR' : 'b2b_en');
  });
  const emit = __ctx.emit;
  return {
    storeLanguage,
    emit
  };
};
export default __sfc_main;
