













import { useRoute, ref, onBeforeMount, onMounted, watch } from '@nuxtjs/composition-api';
import SelectedLanguageModal from '../components/General/Modal/SelectedLanguageModal.vue';
import { useLanguageStore } from '~/components/Header/StoreSwitcher/storeLanguage';
import HeaderLogo from '../components/Header/HeaderLogo';
import TopBar from '../components/Header/TopBar/TopBar';
import AppHeaderMobile from '../components/Header/AppHeaderMobile';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const languageStore = useLanguageStore();
  const openSelectLanguageModal = ref<Boolean>(false);
  const openStoreSwitcherModal = () => {
    openSelectLanguageModal.value = true;
    document.body.style.overflow = 'hidden';
  };
  const closeSelectedLanguage = () => {
    openSelectLanguageModal.value = false;
    document.body.style.overflow = '';
  };
  const setlanguageStore = () => {
    const newLanguage = route.value.fullPath.includes('b2bFR') ? 'b2bFR' : 'b2b_en';
    languageStore.setLanguage(newLanguage);
  };
  watch(route, () => {
    setlanguageStore();
  });
  onBeforeMount(async () => {
    await setlanguageStore();
  });
  return {
    route,
    languageStore,
    openSelectLanguageModal,
    openStoreSwitcherModal,
    closeSelectedLanguage
  };
};
__sfc_main.components = Object.assign({
  TopBar,
  HeaderLogo,
  AppHeaderMobile,
  SelectedLanguageModal
}, __sfc_main.components);
export default __sfc_main;
