



















































































import { useRoute, ref, onMounted, computed, onBeforeMount, watch } from '@nuxtjs/composition-api';
import { SfButton, SfImage, SfRadio } from '@storefront-ui/vue';
import { useLanguageStore } from '../../Header/StoreSwitcher/storeLanguage.ts';
import { useConfig, useStore } from '../../../composables';
import WhiteButton from '../Button/WhiteButton';
import DarkButton from '../Button/DarkButton';
const __sfc_main = {};
__sfc_main.props = {
  selectedStoreDefault: {
    type: String,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const {
    config: storeConfig
  } = useConfig();
  const route = useRoute();
  const languageStore = useLanguageStore();
  const storeLanguage = computed(() => {
    return languageStore.language ?? (route.value.fullPath.includes('b2bFR') ? 'b2bFR' : 'b2b_en');
  });
  const {
    stores,
    change: changeStore,
    load: loadStores
  } = useStore();
  const availableStores = computed(() => stores.value ?? []);
  const selectedStore = ref(null);
  const changeStoreSelected = (store: object) => {
    selectedStore.value = store;
  };
  const setlanguageStore = () => {
    selectedStore.value = stores.value.find(store => store.code === languageStore.language) || stores.value.find(store => store.code === props.selectedStoreDefault);
  };
  watch(() => props.selectedStoreDefault, () => {
    setlanguageStore();
  });
  onMounted(async () => {
    if (!stores.value.length) {
      await loadStores();
    }
    setlanguageStore();
  });
  return {
    emit,
    changeStore,
    availableStores,
    selectedStore,
    changeStoreSelected
  };
};
__sfc_main.components = Object.assign({
  SfRadio,
  DarkButton,
  WhiteButton
}, __sfc_main.components);
export default __sfc_main;
