









































import { ref, onMounted } from '@nuxtjs/composition-api';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { CategoryTree, ProductInterface, Products } from '~/modules/GraphQL/types';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import HeaderLogo from './HeaderLogo.vue';
import StoreSwitcher from './/StoreSwitcher/StoreSwitcher.vue';
import HeaderShortcuts from '@/components/Header/Shortcuts/Shortcut.vue';
import SearchResults from '@/components/Header/SearchBar/SearchResults.vue';
import SearchBar from '@/components/Header/SearchBar/SearchBar.vue';
import PriceHider from '@/components/Header/PriceHider/PriceHider.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    categories: categoryList,
    load: categoriesListLoad
  } = useCategory();
  const productSearchResults = ref<ProductInterface[] | null>(null);
  const loadingProduct = ref<ProductInterface[]>(null);
  const categoryTree = ref<CategoryTree[]>([]);
  const isSearchOpen = ref<boolean>(false);
  const searchTerm = ref<string>('null');
  const openSearch = ref<boolean>(false);
  const closeSearch = () => {
    if (isSearchOpen.value === true) {
      openSearch.value = false;
      isSearchOpen.value = false;
    }
  };
  const handleSearch = () => {
    openSearch.value = !openSearch.value;
  };
  onMounted(async () => {
    await categoriesListLoad({
      pageSize: 20
    });
    if (categoryList.value?.[0]?.children) {
      categoryTree.value = categoryList.value?.[0]?.children.filter(category => category.include_in_menu);
    }
  });
  return {
    emit,
    productSearchResults,
    loadingProduct,
    categoryTree,
    isSearchOpen,
    searchTerm,
    openSearch,
    closeSearch,
    handleSearch
  };
};
__sfc_main.components = Object.assign({
  HeaderNavigation,
  HeaderLogo,
  PriceHider,
  StoreSwitcher,
  HeaderShortcuts,
  SearchBar,
  SearchResults
}, __sfc_main.components);
export default __sfc_main;
