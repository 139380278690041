import type { CustomerCreateInput } from '~/modules/GraphQL/types';

export const generateUserData = (userData): CustomerCreateInput => {
  const baseData = {
    email: userData.email,
    firstname: userData.firstName || userData.firstname,
    lastname: userData.lastName || userData.lastname,
  } as CustomerCreateInput;

  if (Object.prototype.hasOwnProperty.call(userData, 'is_subscribed')) {
    baseData.is_subscribed = userData.is_subscribed;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'dateOfBirth') || Object.prototype.hasOwnProperty.call(userData, 'date_of_birth')) {
    baseData.date_of_birth = userData.dateOfBirth || userData.date_of_birth;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'gender')) {
    if (userData.gender === 'Male') {
      baseData.gender = 1;
    } else if (userData.gender === 'Female') {
      baseData.gender = 2;
    }
  }
  if (Object.prototype.hasOwnProperty.call(userData, 'phone_number')) {
    baseData.phone_number = userData.phone_number;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'nameSociety')) {
    baseData.company_name = userData.nameSociety;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'adressSociety')) {
    baseData.company_address = userData.adressSociety;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'postalCode')) {
    baseData.company_zipcode = userData.postalCode;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'city')) {
    baseData.company_city = userData.city;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'positionHeld')) {
    baseData.company_job_held = userData.positionHeld;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'storeAdress')) {
    baseData.company_store_address = userData.storeAdress;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'company_website')) {
    baseData.company_website = userData.company_website;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'country')) {
    baseData.company_country = userData.country;
  }

  /* if (Object.prototype.hasOwnProperty.call(userData, 'doc')) {
    baseData.tva_file = userData.doc;
  } */

  if (Object.prototype.hasOwnProperty.call(userData, 'cgu_checked')) {
    baseData.cgu_checked = userData.cgu_checked;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'tva')) {
    baseData.taxvat = userData.tva;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'prefix')) {
    baseData.prefix = userData.prefix;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'suffix')) {
    baseData.suffix = userData.suffix;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'password')) {
    baseData.password = userData.password;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'recaptchaToken')) {
    baseData.recaptchaToken = userData.recaptchaToken;
  }

  return baseData;
};
