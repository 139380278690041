var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"focusout":function($event){return _vm.closeSearch()}}},[_c('SfMegaMenu',{staticClass:"search",attrs:{"visible":_vm.visible,"title":_vm.$t('Search results')}},[_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.loading || _vm.loadingProduct)?_c('div',{staticClass:"loader"},[_c('SfLoader',{attrs:{"loading":true}})],1):_vm._e(),_vm._v(" "),(_vm.searchResults && _vm.searchResults.length > 0)?_c('div',{key:"results",staticClass:"search__wrapper-results"},[_c('div',{staticClass:"search__wrapper-results__product"},[_c('SfMegaMenuColumn',{staticClass:"sf-mega-menu-column--pined-content-on-mobile search__results",attrs:{"title":_vm.$t('Product suggestions')},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var title = ref.title;
return [_c('SfMenuItem',{staticClass:"sf-mega-menu-column__header search__header",attrs:{"label":title},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_vm._v(" ​")]},proxy:true}],null,true)})]}}],null,false,1309102888)},[_vm._v(" "),_c('SfScrollable',{staticClass:"results--desktop desktop-only",attrs:{"show-text":"","hide-text":""}},[_c('div',{staticClass:"results-listing"},_vm._l((_vm.searchResultsWithWishlistInfo),function(product,index){return _c('SfProductCard',{key:index,staticClass:"result-card",attrs:{"badgeLabel":_vm.handleDiscountPercentage(product),"badgeColor":"","image-tag":"nuxt-img","regular-price":product.stock_status ==='OUT_OF_STOCK'?_vm.productGetters.getPrice(product).special &&
                    _vm.$fc(_vm.productGetters.getPrice(product).special)?_vm.$fc(_vm.productGetters.getPrice(product).special):
                    _vm.$fc(_vm.productGetters.getPrice(product).regular):_vm.$fc(_vm.productGetters.getPrice(product).regular),"special-price":product.stock_status ==='OUT_OF_STOCK'? null:
                    _vm.productGetters.getPrice(product).special &&
                    _vm.$fc(_vm.productGetters.getPrice(product).special),"max-rating":5,"score-rating":_vm.productGetters.getAverageRating(product),"reviews-count":_vm.productGetters.getTotalReviews(product),"image-width":_vm.imageSizes.productCard.width,"image-height":_vm.imageSizes.productCard.height,"image":_vm.getMagentoImage(
                      _vm.productGetters.getProductThumbnailImage(product)
                    ),"nuxt-img-config":{
                    fit: 'cover',
                  },"alt":_vm.productGetters.getName(product),"title":_vm.productGetters.getName(product),"link":_vm.localePath(
                      ("/p/" + (_vm.productGetters.getProductSku(
                        product
                      )) + (_vm.productGetters.getSlug(
                        product,
                        product.categories[0]
                      )))
                    ),"wishlist-icon":_vm.isAuthenticated ? 'heart' : '',"is-in-wishlist-icon":_vm.isAuthenticated ? 'heart_fill' : '',"is-in-wishlist":product.isInWishlist},on:{"click:wishlist":function($event){return _vm.addItemToWishlist(product)}}})}),1)]),_vm._v(" "),_c('div',{staticClass:"results--mobile smartphone-only"},_vm._l((_vm.searchResultsWithWishlistInfo),function(product,index){return _c('SfProductCard',{key:index,staticClass:"result-card",attrs:{"badgeLabel":_vm.handleDiscountPercentage(product),"badgeColor":"","image-tag":"nuxt-img","regular-price":product.stock_status ==='OUT_OF_STOCK'?_vm.productGetters.getPrice(product).special &&
                    _vm.$fc(_vm.productGetters.getPrice(product).special)?_vm.$fc(_vm.productGetters.getPrice(product).special):
                    _vm.$fc(_vm.productGetters.getPrice(product).regular):_vm.$fc(_vm.productGetters.getPrice(product).regular),"special-price":product.stock_status ==='OUT_OF_STOCK'? null:
                    _vm.productGetters.getPrice(product).special &&
                    _vm.$fc(_vm.productGetters.getPrice(product).special),"max-rating":5,"score-rating":_vm.productGetters.getAverageRating(product),"reviews-count":_vm.productGetters.getTotalReviews(product),"image-width":_vm.imageSizes.productCardHorizontal.width,"image-height":_vm.imageSizes.productCardHorizontal.height,"image":_vm.getMagentoImage(
                    _vm.productGetters.getProductThumbnailImage(product)
                  ),"nuxt-img-config":{
                  fit: 'cover',
                },"alt":_vm.productGetters.getName(product),"title":_vm.productGetters.getName(product),"link":_vm.localePath(
                    ("/p/" + (_vm.productGetters.getProductSku(
                      product
                    )) + (_vm.productGetters.getSlug(
                      product,
                      product.categories[0]
                    )))
                  ),"wishlist-icon":_vm.isAuthenticated ? 'heart' : '',"is-in-wishlist-icon":_vm.isAuthenticated ? 'heart_fill' : '',"is-in-wishlist":product.isInWishlist},on:{"click:wishlist":function($event){return _vm.addItemToWishlist(product)}}})}),1)],1)],1)]):_c('div',{key:"no-results",staticClass:"before-results"},[(
            _vm.searchTerm !== '' && _vm.searchResults && _vm.searchResults.length === 0
          )?_c('div',[_c('SvgImage',{staticClass:"before-results__picture",attrs:{"icon":"error_image","label":_vm.$t('Error'),"width":"250","height":"250"}}),_vm._v(" "),_c('p',{staticClass:"before-results__paragraph"},[_vm._v("\n            "+_vm._s(_vm.$t('We did not find anything for'))+" \""+_vm._s(_vm.searchTerm)+"\"\n          ")])],1):_c('div',[_c('div',{staticClass:"container-loader"},[_c('SfLoader',{attrs:{"loading":true}})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }