




















































import iconMobileJson from '@/components/Header/Shortcuts/Icons-mobile.json';
import HeaderLogo from './HeaderLogo.vue';
import HeaderShortcuts from '@/components/Header/Shortcuts/Shortcut.vue';
import { SfButton } from '@storefront-ui/vue';
import { useCategoryStore } from '@/modules/catalog/category/stores/category';
import { ProductInterface, Products, useUiState } from '@/composables';
import MobileCategorySidebar from '@/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue';
import { ref } from '@nuxtjs/composition-api';
import SearchBar from '@/components/Header/SearchBar/SearchBar.vue';
import SearchResults from '@/components/Header/SearchBar/SearchResults.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const loadingProduct = ref<ProductInterface[]>(null);
  const searchTerm = ref<string>('null');
  const productSearchResults = ref<ProductInterface[] | null>(null);
  const isSearchOpen = ref<boolean>(false);
  const openSearch = ref<boolean>(false);
  const {
    toggleMobileMenu,
    isMobileMenuOpen
  } = useUiState();
  const handleMenuClick = () => {
    loadMenu();
  };
  const handleSearchClick = () => {
    console.info('Search clicked');
    openSearch.value = !openSearch.value;
  };
  const loadMenu = async () => {
    const categories = useCategoryStore();
    if (categories.categories === null) {
      await categories.load();
    }
    toggleMobileMenu();
  };
  const closeSearch = () => {
    if (isSearchOpen.value === true) {
      openSearch.value = false;
      isSearchOpen.value = false;
    }
  };
  const toggleSearchResultComponent = () => {
    isSearchOpen.value = !isSearchOpen.value;
  };
  return {
    iconMobileJson,
    loadingProduct,
    searchTerm,
    productSearchResults,
    isSearchOpen,
    openSearch,
    isMobileMenuOpen,
    handleMenuClick,
    handleSearchClick,
    closeSearch,
    toggleSearchResultComponent
  };
};
__sfc_main.components = Object.assign({
  SfButton,
  HeaderLogo,
  HeaderShortcuts,
  MobileCategorySidebar,
  SearchBar,
  SearchResults
}, __sfc_main.components);
export default __sfc_main;
